import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Badge,
  Alert
} from '@mui/material';
import {
  Schedule,
  Warning,
  PriorityHigh,
  CheckCircle,
  Person,
  NotificationsActive,
  Add,
  Edit,
  Delete,
  TrendingUp,
  Block
} from '@mui/icons-material';
import { format, differenceInDays } from 'date-fns';
import ScheduledReachOutsView from './ScheduledReachOutsView';

const TaskManagementView = ({
  tasks,
  scheduledCallbacks,
  leads,
  priorityTaskData,
  onEditTask,
  onDeleteTask,
  onCreateTask,
  onEditCallback,
  onDeleteCallback,
  onCreateCallback,
  onLeadClick,
  userTimezone,
  theme
}) => {
  const [selectedView, setSelectedView] = useState('priority');
  const [staleLeads, setStaleLeads] = useState([]);
  const [taskFilter, setTaskFilter] = useState('all');

  // Helper function to convert text to title case
const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

  useEffect(() => {
    const calculateStaleLeads = () => {
      return leads.filter(lead => {
        const daysSinceContact = lead.last_contact_date ? 
          differenceInDays(new Date(), new Date(lead.last_contact_date)) : 
          Number.POSITIVE_INFINITY;

        return (
          (daysSinceContact <= 7 && !lead.daily_followup_done) ||
          (daysSinceContact <= 14 && !lead.alternate_followup_done) ||
          (daysSinceContact <= 30 && !lead.weekly_followup_done) ||
          (daysSinceContact > 30 && lead.status !== 'dead')
        );
      });
    };

    setStaleLeads(calculateStaleLeads());
  }, [leads]);

  const formatLeadName = (lead) => {
    return `${lead.first_name || ''} ${lead.last_name || ''}`.trim() || 'N/A';
  };

  const getStatusName = (lead) => {
    if (lead.status_name) {
      return lead.status_name;
    }
    return 'No Status';
  };

  const getTaskStatusName = (status) => {
    if (!status) return 'N/A';
    return typeof status === 'object' ? status.name : status;
  };

  const getStatusColor = (lead) => {
    const statusName = lead.status_name?.toLowerCase() || '';
    
    switch (statusName) {
      case 'dead':
        return 'error';
      case 'qualified':
        return 'success';
      case 'disqualified':
        return 'error';
      case 'new contact':
        return 'info';
      case 'no status':
        return 'default';
      default:
        return 'primary';
    }
  };

  const categorizeLeads = () => {
    const categories = {
      daily: [],
      alternate: [],
      weekly: [],
      dead: []
    };

    staleLeads.forEach(lead => {
      const daysSinceContact = lead.last_contact_date ? 
        differenceInDays(new Date(), new Date(lead.last_contact_date)) : 
        Number.POSITIVE_INFINITY;

      const formattedLead = {
        ...lead,
        displayName: formatLeadName(lead),
        statusName: getStatusName(lead)
      };

      if (daysSinceContact <= 7) categories.daily.push(formattedLead);
      else if (daysSinceContact <= 14) categories.alternate.push(formattedLead);
      else if (daysSinceContact <= 30) categories.weekly.push(formattedLead);
      else categories.dead.push(formattedLead);
    });

    return categories;
  };

  const priorityTasks = [
    {
      title: "New Leads",
      leads: priorityTaskData.new_leads.map(lead => ({
        ...lead,
        displayName: formatLeadName(lead),
      })),
      priority: "high",
      icon: <Warning sx={{ color: 'warning.main' }} />
    },
    {
      title: "Warm Leads",
      leads: priorityTaskData.warm_leads.map(lead => ({
        ...lead,
        displayName: formatLeadName(lead),
      })),
      priority: "high",
      icon: <TrendingUp sx={{ color: 'success.main' }} />
    },
    {
      title: "Daily Follow-ups",
      leads: priorityTaskData.daily_followups.map(lead => ({
        ...lead,
        displayName: formatLeadName(lead),
      })),
      priority: "medium",
      icon: <Schedule sx={{ color: 'info.main' }} />
    },
    {
      title: "Alternate Day Follow-ups",
      leads: priorityTaskData.alternate_followups.map(lead => ({
        ...lead,
        displayName: formatLeadName(lead),
      })),
      priority: "medium",
      icon: <Schedule sx={{ color: 'primary.main' }} />
    },
    {
      title: "Weekly Follow-ups",
      leads: priorityTaskData.weekly_followups.map(lead => ({
        ...lead,
        displayName: formatLeadName(lead),
      })),
      priority: "low",
      icon: <Schedule sx={{ color: 'secondary.main' }} />
    },
    {
      title: "Dead Leads",
      leads: priorityTaskData.dead_leads.map(lead => ({
        ...lead,
        displayName: formatLeadName(lead),
      })),
      priority: "low",
      icon: <Block sx={{ color: 'error.main' }} />
    }
  ];

  const renderPriorityView = () => (
    <Grid container spacing={2}>
      {priorityTasks.map((category, index) => (
        <Grid item xs={12} key={index}>
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  {category.icon}
                  <Typography variant="h6">{category.title}</Typography>
                  <Badge badgeContent={category.leads.length} color="primary" />
                </Box>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  onClick={() => onCreateCallback()}
                >
                  Schedule Follow-up
                </Button>
              </Box>
              
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lead</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Last Contact</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {category.leads.map(lead => (
                      <TableRow key={lead.id}>
                        <TableCell>
                          <Button
                            onClick={() => onLeadClick(lead.id)}
                            sx={{ textTransform: 'none' }}
                          >
                            {lead.displayName}
                          </Button>
                        </TableCell>
                        <TableCell>
                          {lead.phone_number || 'N/A'}
                        </TableCell>
                        <TableCell>
                          {lead.last_contact_date ? 
                            format(new Date(lead.last_contact_date), 'MMM dd, yyyy') :
                            'Never'}
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={getStatusName(lead)}
                            color={getStatusColor(lead)}
                            size="small"
                            sx={{ 
                              minWidth: '90px',
                              justifyContent: 'center'
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton 
                            size="small"
                            onClick={() => onCreateCallback({ leadId: lead.id })}
                          >
                            <Schedule />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderTasksView = () => (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Filter Tasks</InputLabel>
          <Select
            value={taskFilter}
            onChange={(e) => setTaskFilter(e.target.value)}
            label="Filter Tasks"
          >
            <MenuItem value="all">All Tasks</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={onCreateTask}
        >
          Create Task
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Assigned To</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks
              .filter(task => taskFilter === 'all' || task.status === taskFilter)
              .map(task => (
                <TableRow key={task.id}>
                  <TableCell>{task.title}</TableCell>
                  <TableCell>
                    {task.due_date ? format(new Date(task.due_date), 'MMM dd, yyyy HH:mm') : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={task.priority}
                      color={
                        task.priority === 'high' ? 'error' :
                        task.priority === 'medium' ? 'warning' : 
                        'success'
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {task.assigned_to?.first_name 
                      ? `${task.assigned_to.first_name} ${task.assigned_to.last_name}`
                      : 'Unassigned'}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={getTaskStatusName(task.status)}
                      color={task.status === 'completed' ? 'success' : 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => onEditTask(task)}>
                      <Edit />
                    </IconButton>
                    <IconButton size="small" onClick={() => onDeleteTask(task.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={selectedView} onChange={(_, newValue) => setSelectedView(newValue)}>
          <Tab 
            value="priority" 
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <PriorityHigh />
                Priority Actions
              </Box>
            } 
          />
          <Tab 
            value="tasks" 
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <CheckCircle />
                General Tasks
              </Box>
            } 
          />
          <Tab 
            value="callbacks" 
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <Schedule />
                Scheduled Callbacks
              </Box>
            } 
          />
        </Tabs>
      </Box>

      {selectedView === 'priority' && renderPriorityView()}
      {selectedView === 'tasks' && renderTasksView()}
      {selectedView === 'callbacks' && (
        <ScheduledReachOutsView
          scheduledReachOuts={scheduledCallbacks}
          onCreateCallback={onCreateCallback}
          onEditCallback={onEditCallback}
          onDeleteCallback={onDeleteCallback}
          userTimezone={userTimezone}
          theme={theme}
        />
      )}

      <Alert severity="info" sx={{ mt: 2 }}>
        Follow-up Decay Methodology:
        • First week: Daily follow-ups
        • Second week: Alternate day follow-ups
        • Weeks 3-4: Weekly follow-ups
        • After 30 days: Lead marked as "dead" - requires reactivation
      </Alert>
    </Box>
  );
};

TaskManagementView.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    due_date: PropTypes.string,
    priority: PropTypes.string,
    status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string
      })
    ]),
    assigned_to: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  })).isRequired,
  scheduledCallbacks: PropTypes.array.isRequired,
  leads: PropTypes.array.isRequired,
  priorityTaskData: PropTypes.shape({
    new_leads: PropTypes.array.isRequired,
    warm_leads: PropTypes.array.isRequired,
    daily_followups: PropTypes.array.isRequired,
    alternate_followups: PropTypes.array.isRequired,
    weekly_followups: PropTypes.array.isRequired,
    dead_leads: PropTypes.array.isRequired
  }).isRequired,
  onEditTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  onCreateTask: PropTypes.func.isRequired,
  onEditCallback: PropTypes.func.isRequired,
  onDeleteCallback: PropTypes.func.isRequired,
  onCreateCallback: PropTypes.func.isRequired,
  onLeadClick: PropTypes.func.isRequired,
  userTimezone: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default TaskManagementView;